const Layout = () => import('@/layout/index.vue')

export default {
    path: '/system/user',
    component: Layout,
    redirect: '/system/user/list',
    meta: {
        i18n: 'route.system.user.name',
        icon: 'ri:user-settings-line',
        auth: ['system_user']
    },
    children: [
        {
            path: 'list',
            name: 'systemUserList',
            component: () => import('@/views/system/user/list.vue'),
            meta: {
                i18n: 'route.system.user.list',
                cache: true,
                auth: ['system_user_list']
            }
        },
        {
            path: 'add',
            name: 'systemUserAdd',
            component: () => import('@/views/system/user/form.vue'),
            meta: {
                i18n: 'route.system.user.add',
                cache: true,
                auth: ['system_user_add']
            }
        },
        {
            path: 'update/:id',
            name: 'systemUserUpdate',
            component: () => import('@/views/system/user/form.vue'),
            meta: {
                i18n: 'route.system.user.update',
                sidebar: false,
                activeMenu: '/system/user/list',
                cache: false,
                auth: ['system_user_update']
            }
        }
    ]
}
