const Layout = () => import('@/layout/index.vue')

export default {
    path: '/bidding',
    component: Layout,
    redirect: { name: 'allBiddingList' },
    meta: {
        i18n: 'route.bidding.bidding.name',
        icon: 'ic:outline-window',
        auth: ['bidding']
    },
    children: [
        {
            path: 'list',
            name: 'allBiddingList',
            component: () => import('@/views/bidding/bidding/allList.vue'),
            meta: {
                i18n: 'route.bidding.bidding.allBidding.name',
                auth: ['bidding_bidding_list'],
                cache: true
            }
        },
        {
            path: 'followingList',
            name: 'followingList',
            component: () => import('@/views/bidding/bidding/followingList.vue'),
            meta: {
                i18n: 'route.bidding.bidding.followingBidding.name',
                auth: ['bidding_bidding_list'],
                cache: true,
                badgeKey: 'followingCount'
            }
        },
        {
            path: 'signBiddingList',
            name: 'signBiddingList',
            component: () => import('@/views/bidding/bidding/signList.vue'),
            meta: {
                i18n: 'route.bidding.bidding.signBidding.name',
                auth: ['bidding_bidding_list'],
                cache: true,
                badgeKey: 'signUpCount'
            }
        },
        {
            path: 'unsignBiddingList',
            name: 'unsignBiddingList',
            component: () => import('@/views/bidding/bidding/unsignList.vue'),
            meta: {
                i18n: 'route.bidding.bidding.unsignBidding.name',
                auth: ['bidding_bidding_list'],
                cache: true,
                badgeKey: 'unSignUpCount'
            }
        },
        {
            path: 'view/:id',
            name: 'biddingView',
            component: () => import('@/views/bidding/bidding/view.vue'),
            meta: {
                i18n: 'route.bidding.bidding.view',
                auth: ['bidding_bidding_view'],
                sidebar: false,
                cache: true,
                activeMenu: '/bidding/list'
            }
        },
        {
            path: 'add',
            name: 'biddingAdd',
            component: () => import('@/views/bidding/bidding/form.vue'),
            meta: {
                i18n: 'route.bidding.bidding.add',
                auth: ['bidding_bidding_add'],
                sidebar: false,
                activeMenu: '/bidding/list'
            }
        }
    ]
}
