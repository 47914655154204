const Layout = () => import('@/layout/index.vue')

export default {
    path: '/biddingAnalyse',
    component: Layout,
    redirect: { name: 'biddingAnalyseDaily' },
    meta: {
        i18n: 'route.bidding.biddingAnalyse.name',
        icon: 'ri:line-chart-line',
        auth: ['bidding_analyse']
    },
    children: [
        {
            path: 'daily',
            name: 'biddingAnalyseDaily',
            component: () => import('@/views/bidding/analyse/daily.vue'),
            meta: {
                i18n: 'route.bidding.biddingAnalyse.daily',
                cache: true
            }
        },
        {
            path: 'monthly',
            name: 'biddingAnalyseMonthly',
            component: () => import('@/views/bidding/analyse/monthly.vue'),
            meta: {
                i18n: 'route.bidding.biddingAnalyse.monthly',
                cache: true
            }
        },
        {
            path: 'advice',
            name: 'biddingAnalyseAdvice',
            component: () => import('@/views/bidding/analyse/advice.vue'),
            meta: {
                i18n: 'route.bidding.biddingAnalyse.advice',
                cache: true
            }
        },
        {
            path: 'manual',
            name: 'biddingAnalyseManual',
            component: () => import('@/views/bidding/analyse/manual.vue'),
            meta: {
                i18n: 'route.bidding.biddingAnalyse.manual',
                cache: true
            }
        },
        {
            path: 'openTender',
            name: 'biddingOpenTender',
            component: () => import('@/views/bidding/analyse/openTender.vue'),
            meta: {
                title: '开标情况',
                cache: true
            }
        },
        {
            path: 're/unsignup',
            name: 'biddingAnalyseReUnsignup',
            component: () => import('@/views/bidding/analyse/reUnsignup.vue'),
            meta: {
                i18n: 'route.bidding.biddingAnalyse.unsignup',
                cache: true
            }
        },
        {
            path: 'oppositeAnalyse',
            name: 'oppositeAnalyse',
            component: () => import('@/views/bidding/analyse/oppositeAnalyse.vue'),
            meta: {
                title: '对手分析',
                cache: true
            }
        }
    ]
}
