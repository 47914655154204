const Layout = () => import('@/layout/index.vue')

export default {
    path: '/certification/approve',
    component: Layout,
    redirect: { name: 'certificationApproveView' },
    meta: {
        title: '审批',
        icon: 'ri:barcode-box-line',
        sidebar: false
    },
    children: [
        {
            path: ':id/certification/:certificationId',
            name: 'certificationApproveView',
            component: () => import('@/views/certification/approve/view.vue'),
            meta: {
                title: '审批',
                sidebar: false
            }
        }
    ]
}
