import { auth, authAll } from '@/util'
import { useTimeago } from '@/composables'
import Waves from 'node-waves'
import 'node-waves/dist/waves.min.css'

export default function directive(app) {
    // 注册 v-auth 和 v-auth-all 指令
    app.directive('auth', {
        mounted: (el, binding) => {
            if (!auth(binding.value)) {
                el.remove()
            }
        }
    })
    app.directive('auth-all', {
        mounted: (el, binding) => {
            if (!authAll(binding.value)) {
                el.remove()
            }
        }
    })
    app.directive('timeago', {
        mounted: (el, binding) => {
            el.innerText = useTimeago().format(binding.value)
            if (binding.modifiers.interval) {
                el.__timeagoSetInterval__ = setInterval(() => {
                    el.innerText = useTimeago().format(binding.value)
                }, 1000)
            }
        },
        beforeUnmount: (el, binding) => {
            if (binding.modifiers.interval) {
                clearInterval(el.__timeagoSetInterval__)
            }
        }
    })
    // 注册 Waves 指令
    app.directive('waves', {
        created: () => {
            Waves.init()
        },
        mounted: (el, binding) => {
            let classes = ['button', 'circle', 'block', 'float', 'light', 'classic'].filter(cls => binding.modifiers[cls]).map(cls => `waves-${cls}`)
            Waves.attach(el, classes)
        }
    })
    app.directive('dropdown', {
        mounted: (el, binding) => {
            console.log('dropdown mounted', el, binding)
        }
    })
    app.directive('hyposensitization', {
        mounted: (el, binding) => {
            const hyposensiyizationed = binding.value.replace(/(\d{1})\d*(\d{1})/g, '$1****$2')
            el.innerText = hyposensiyizationed
        },
        updated: (el, binding) => {
            // const hyposensiyizationed = binding.value.replace(/验证码(?:为|是|)(?:：|:|)(\d{1})\d*(\d{1})/, '验证码为：$1****$2')
            const hyposensiyizationed = binding.value.replace(/(\d{1})\d{1,3}(\d{1})/g, '$1****$2')
            el.innerText = hyposensiyizationed
        }
    })
}
