import { getRequest } from '@/api'
import qs from 'qs'

/* 接收消息*/
export function getMineMessage(params) {
    return getRequest({
        url: '/mc/message/mine',
        params,
        showLoading: false,
        paramsSerializer: params => qs.stringify(params, { allowDots: true })
    })
}

/* 获取连接*/
export function getMessageStream() {
    return getRequest({
        url: '/mc/message/stream'
    })
}

