const Layout = () => import('@/layout/index.vue')

export default {
    path: '/sale/contract',
    component: Layout,
    redirect: '/sale/contract/list',
    meta: {
        i18n: 'route.sale.contract.name',
        icon: 'ri:contract-line',
        auth: ['sale_contract']
    },
    children: [
        {
            path: 'list',
            name: 'saleContractList',
            component: () => import('@/views/sale/contract/list.vue'),
            meta: {
                i18n: 'route.sale.contract.list',
                cache: true
            }
        },
        {
            path: 'view/:id',
            name: 'saleContractView',
            component: () => import('@/views/sale/contract/view.vue'),
            meta: {
                i18n: 'route.sale.contract.view',
                auth: ['sale_contract_view'],
                sidebar: false,
                activeMenu: '/sale/contract/list',
                cache: true
            }
        },
        {
            path: 'update/:id',
            name: 'saleContractUpdate',
            component: () => import('@/views/sale/contract/form.vue'),
            meta: {
                cache: true,
                i18n: 'route.sale.contract.update',
                sidebar: false,
                activeMenu: '/sale/contract/list',
                auth: ['sale_contract_update']
            }
        },
    ]
}
