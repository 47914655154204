const Layout = () => import('@/layout/index.vue')

export default {
    path: '/sale/project',
    component: Layout,
    redirect: '/sale/project/list',
    meta: {
        i18n: 'route.sale.project.name',
        icon: 'icon-park-solid:table-report',
        auth: ['sale_quote']
    },
    children: [
        {
            path: 'list',
            name: 'saleInquiryList',
            component: () => import('@/views/sale/project/projectList.vue'),
            meta: {
                i18n: 'route.sale.project.list',
                cache: false,
                auth: ['sale_quote_list']
            }
        },
        {
            path: 'detail/:id',
            name: 'saleInquiryView',
            component: () => import('@/views/sale/project/projectDetail.vue'),
            meta: {
                i18n: 'route.sale.project.view',
                sidebar: false,
                activeMenu: '/sale/project/list',
                cache: false,
                auth: ['sale_quote_view']
            }
        },
        {
            path: 'add',
            name: 'inquiryAdd',
            component: () => import('@/views/sale/project/addProject.vue'),
            meta: {
                i18n: 'route.sale.project.add',
                activeMenu: '/sale/project/add',
                auth: ['sale_quote_add']
            }
        },
        {
            path: 'update/:id',
            name: 'inquiryUpdate',
            component: () => import('@/views/sale/project/updateProject.vue'),
            meta: {
                i18n: 'route.sale.project.update',
                sidebar: false,
                activeMenu: '/sale/project/list',
                auth: ['sale_quote_update']
            }
        }
    ]
}
