import order from './order'
import contract from './contract'
import inquiry from './inquiry'

export default {
    meta: {
        i18n: 'route.sale.name',
        icon: 'ri:funds-box-line',
        auth: ['sale']
    },
    children: [
        contract,
        order,
        inquiry
    ]
}
