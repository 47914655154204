const Layout = () => import('@/layout/index.vue')

export default {
    path: '/system/team',
    component: Layout,
    redirect: '/system/team/list',
    meta: {
        i18n: 'route.system.team.name',
        icon: 'ant-design:team-outlined',
        auth: ['system_team']
    },
    children: [
        {
            path: 'list',
            name: 'systemTeamList',
            component: () => import('@/views/system/team/list.vue'),
            meta: {
                i18n: 'route.system.team.list',
                cache: true,
                auth: ['system_team_list']
            }
        },
        {
            path: 'add',
            name: 'systemTeamAdd',
            component: () => import('@/views/system/team/form.vue'),
            meta: {
                i18n: 'route.system.team.add',
                cache: true,
                auth: ['system_team_add']
            }
        },
        {
            path: 'update/:id',
            name: 'systemTeamUpdate',
            component: () => import('@/views/system/team/form.vue'),
            meta: {
                i18n: 'route.system.team.update',
                sidebar: false,
                activeMenu: '/system/team/list',
                cache: false,
                auth: ['system_team_update']
            }
        }
    ]
}
