import path from 'path-browserify'
import useSettingsStore from '@/store/modules/settings'
import useUserStore from '@/store/modules/user'

// 深拷贝
export function deepClone(target) {
    // 定义一个变量
    let result
    // 如果当前需要深拷贝的是一个对象的话
    if (typeof target === 'object') {
        // 如果是一个数组的话
        if (Array.isArray(target)) {
            result = [] // 将result赋值为一个数组，并且执行遍历
            for (let i in target) {
                // 递归克隆数组中的每一项
                result.push(deepClone(target[i]))
            }
            // 判断如果当前的值是null的话；直接赋值为null
        } else if (target === null) {
            result = null
            // 判断如果当前的值是一个RegExp对象的话，直接赋值
        } else if (target.constructor === RegExp) {
            result = target
        } else {
            // 否则是普通对象，直接for in循环，递归赋值对象的所有值
            result = {}
            for (let i in target) {
                result[i] = deepClone(target[i])
            }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
    } else {
        result = target
    }
    // 返回最终结果
    return result
}

// 防抖
export const debounce = (fn, delay = 300, immediate = true) => {
    let timer = null // 借助闭包
    return function() {
        if (timer) {
            clearTimeout(timer)
        }
        if (immediate) {
            let callNow = !timer
            timer = setTimeout(() => {
                timer = null
            }, delay)
            if (callNow) {
                fn.apply(this, arguments)
            }
        } else {
            timer = setTimeout(() => {
                fn.apply(this, arguments)
            }, delay)
        }
    }
}

// 节流
export function throttle(fn, delay = 300) {
    let valid = true
    return function() {
        if (!valid) {
            return false
        }
        // 工作时间，执行函数并且在间隔期内把状态位设为无效
        valid = false
        setTimeout(() => {
            fn.apply(this, arguments)
            valid = true
        }, delay)
    }
}

function hasPermission(permission) {
    const settingsStore = useSettingsStore()
    const userStore = useUserStore()
    if (settingsStore.app.enablePermission) {
        return userStore.permissions.some(v => {
            return v === permission
        })
    } else {
        return true
    }
}

export function auth(value) {
    let auth
    if (!value || (Array.isArray(value) && value.length === 0)) {
        return true
    }
    if (typeof value === 'string') {
        auth = hasPermission(value)
    } else {
        auth = value.some(item => {
            return hasPermission(item)
        })
    }
    return auth
}

export function authAll(value) {
    const auth = value.every(item => {
        return hasPermission(item)
    })
    return auth
}

export function isExternalLink(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

export function resolveRoutePath(basePath, routePath) {
    if (isExternalLink(routePath)) {
        return routePath
    }
    if (isExternalLink(basePath)) {
        return basePath
    }
    return basePath ? path.resolve(basePath, routePath) : routePath
}

/**
 * @description: 后端返回的数据生成树结构
 * @param {*} data
 * @return Array
 */
export function genTree(data) {
    const result = []
    if (!Array.isArray(data)) {
        return result
    }
    // remove children
    data.forEach(item => {
        delete item.children
    })
    // 归整数据格式
    const map = {}
    data.forEach(item => {
        item.label = item.name
        item.value = item.id
        map[item.id] = item
    })
    // 根据父子关系归整数据格式
    // debugger
    data.forEach(item => {

        if (!item.pid) result.push(item)
        else {
            const parent = map[item.pid]
            if (parent) {
                (parent.children || (parent.children = [])).push(item)
            } else {
                // 搜索时，可能出现父级节点不存在的情况
                // result.push(item)
                console.error(`父级节点${item.pid}不存在`)
            }
        }

    })
    return result
}

/**
 * @description: 后端返回的数据生成树结构  权限树
 * @param {*} data
 * @return Array
 */
export function genPermissionTree(data) {
    const result = []
    if (!Array.isArray(data)) {
        return result
    }
    // remove children
    data.forEach(item => {
        delete item.children
    })
    // 归整数据格式
    const map = {}
    data.forEach(item => {
        item.label = item.name
        item.value = item.id
        map[item.id] = item
    })
    // 根据父子关系归整数据格式
    // debugger
    data.forEach(item => {

        if (!item.pid) result.push(item)
        else {
            const parent = map[item.pid]
            if (parent) {
                (parent.children || (parent.children = [])).push(item)
            } else {
                // 搜索时，可能出现父级节点不存在的情况
                result.push(item)
                console.error(`父级节点${item.pid}不存在`)
            }
        }

    })
    return result
}

/**
 * @description: 文件名取后缀
 * @param {*} fileName
 * @return string
 */
export function getFileSuffix(fileName) {
    const fn = fileName?.split('.') || []
    if (fn.length <= 1) return ''
    return fn[fn.length - 1]
}

/**
 * @description: 合并对象，将后者覆盖前者，只覆盖前者拥有的属性。
 * @param {*} obj1 被覆盖的对象
 * @param {*} obj2 覆盖的对象
 * @return {*}
 */
export function mergeObj(obj1, obj2) {
    let newObj = {}
    Object.keys(obj1).forEach(item => {
        newObj[item] = obj2[item] !== undefined ? obj2[item] : obj1[item]
    })
    return newObj
}

/** 按对象的key排序 */
export function sortByKey(obj) {
    return Object.keys(obj).sort().reduce((p, n) => ((p[n] = obj[n]), p), {})
}

/** 按对象的key去重 */
export const clearByKey = (arr, key) => arr.filter((
    ele, index, a
) => (
    a.map(e => e[key]).indexOf(ele[key])
) === index)

/** 数组中对象值取最小item */
export const minBy = (arr, key) => arr.reduce((a, b) => (a[key] < b[key] ? a : b), {})

/** 数组中对象值取最大item */
export const maxBy = (arr, key) => arr.reduce((a, b) => (a[key] >= b[key] ? a : b), {})

export const PRECISION = 4 // 精度
/** toFixed */
export const toFixed = n => {
    return Number(n).toFixed(PRECISION)
}

export const strTrim = str => {
    if (str === null || str === undefined) return str
    if (typeof str === 'string') return str.trim()
    if (typeof str === 'object') {
        Object.keys(str).forEach(key => {
            str[key] = strTrim(str[key])
        })
        return str
    }
    return str
}

/** 从文件对象中提取文件id */
export const extractFilesId = fileList => {
    return Array.from(
        fileList.map(file => {
            return {
                id: file?.response ? file.response.id : file?.id
            }
        }),
        x => x.id
    )
}

export const tableDateFormatter = (row, column, cellValue) => {
    return cellValue ? cellValue.split(' ')[0] : ''
}

/**
 * 格式化输出文件大小
 * @param fileSizeInBytes {number} 文件字节数
 * @returns {string} 文件大小格式化字符串
 */
export function formatFileSize(fileSizeInBytes) {
    const sizeUnit = ['B', 'KB', 'MB', 'GB', 'TB']
    let sizeType = 0
    if (fileSizeInBytes !== 0) {
        sizeType = Math.floor(Math.log(fileSizeInBytes) / Math.log(1024))
    }
    const size = (fileSizeInBytes / Math.pow(1024, sizeType)).toFixed(2)
    return size + sizeUnit[sizeType]
}

export function getInclude(arr1, arr2) {
    let temp = []
    for (const item of arr2) {
        arr1.find(i => i === item) ? temp.push(item) : ''
    }
    return temp
}
