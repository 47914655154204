import { ref } from 'vue'

/**
 * @description: 创建一个逻辑状态
 * @param {*} defalutValue 默认值为false
 */
export default function useBoolen(defalutValue = false) {
    const bool = ref(defalutValue)
    const toggle = () => {
        bool.value = !bool.value
    }
    const reset = () => {
        bool.value = defalutValue
    }
    const set = value => {
        bool.value = value
    }
    const setTrue = () => {
        bool.value = true
    }
    const setFalse = () => {
        bool.value = false
    }
    return {
        bool,
        toggle,
        reset,
        set,
        setTrue,
        setFalse
    }
}
