const Layout = () => import('@/layout/index.vue')

export default {
    path: '/office/clockIn',
    component: Layout,
    redirect: { name: 'OfficeClockIn' },
    meta: {
        i18n: 'route.office.clock_in.name',
        icon: 'ri:calendar-check-line'
    },
    children: [
        {
            path: '',
            name: 'OfficeClockIn',
            component: () => import('@/views/office/clockIn.vue'),
            meta: {
                sidebar: false,
                activeMenu: '/office/clockIn',
                i18n: 'route.office.clock_in.name'
            }
        }
    ]
}
