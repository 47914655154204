const Layout = () => import('@/layout/index.vue')

export default {
    path: '/sale/order',
    component: Layout,
    redirect: '/sale/order/list',
    meta: {
        i18n: 'route.sale.order.name',
        icon: 'icon-park-solid:doc-success',
        auth: ['sale_order']
    },
    children: [
        {
            path: 'list',
            name: 'saleOrderList',
            component: () => import('@/views/sale/order/list.vue'),
            meta: {
                i18n: 'route.sale.order.list',
                cache: true
            }
        },
        {
            path: 'view/:id',
            name: 'saleOrderView',
            component: () => import('@/views/sale/order/view.vue'),
            meta: {
                i18n: 'route.sale.order.view',
                sidebar: false,
                activeMenu: '/sale/order/list',
                cache: true
            }
        },
    ]
}
