import pinia from '@/store'
import useSettingsStore from '@/store/modules/settings'

let constantRoutes = [
    {
        path: '/',
        redirect: '/dashboard',
        component: () => import('@/layout/guide.vue'),
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('@/views/common/login.vue'),
                meta: {
                    whiteList: true,
                    copyright: true,
                    i18n: 'route.login'
                }
            }
        ]
    },
    {
        path: '/:all(.*)*',
        name: 'notFound',
        component: () => import('@/views/common/404.vue'),
        meta: {
            i18n: 'route.404'
        }
    }
]

let systemRoutes = [
    {
        path: '/dashboard',
        component: () => import('@/layout/index.vue'),
        meta: {
            breadcrumb: false
        },
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('@/views/dashboard/index.vue'),
                meta: {
                    i18n: 'route.dashboard',
                    breadcrumb: false,
                    copyright: true
                }
            }
        ]
    },
    {
        path: '/personal',
        component: () => import('@/layout/index.vue'),
        redirect: '/personal/setting',
        meta: {
            i18n: 'route.personal.center',
            breadcrumb: false
        },
        children: [
            {
                path: '',
                name: 'personalCenter',
                component: () => import('@/views/common/user/index.vue'),
                meta: {
                    i18n: 'route.personal.center',
                    cache: 'personalUpdatePassword'
                }
            },
            {
                path: 'update/mobile',
                name: 'personalUpdateMobile',
                component: () => import('@/views/common/user/update.mobile.vue'),
                meta: {
                    i18n: 'route.personal.updateMobile'
                }
            },
            {
                path: 'message',
                name: 'personalMessage',
                component: () => import('@/views/common/message/index.vue'),
                meta: {
                    i18n: 'route.personal.message'
                }
            }
        ]
    },
    {
        path: '/reload',
        component: () => import('@/layout/index.vue'),
        meta: {
            breadcrumb: false
        },
        children: [
            {
                path: '',
                name: 'reload',
                component: () => import('@/views/reload.vue'),
                meta: {
                    i18n: 'route.reload',
                    breadcrumb: false
                }
            }
        ]
    }
]

import Work from './modules/work'
import System from './modules/system'
import Bidding from './modules/bidding'
import Certification from './modules/certification'
import Office from './modules/office'
import Opportunity from '@/router/modules/opportunity'
import AI from '@/router/modules/ai'
import Contract from '@/router/modules/sale'
import Bee from '@/router/modules/bee'

let asyncRoutes = [
    Work,
    Office,
    Opportunity,
    Bidding,
    Certification,
    AI,
    Bee,
    Contract,
    System
]

import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'

if (useSettingsStore(pinia).app.routeBaseOn === 'filesystem') {
    constantRoutes = generatedRoutes.filter(item => {
        return item.meta?.enabled !== false && item.meta?.constant === true
    })
    asyncRoutes = setupLayouts(generatedRoutes.filter(item => {
        return item.meta?.enabled !== false && item.meta?.constant !== true && item.meta?.layout !== false
    }))
}

export {
    constantRoutes,
    systemRoutes,
    asyncRoutes
}
