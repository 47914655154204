import { format as TAformat, render as TArender, cancel } from 'timeago.js'

export default function useTimeago() {
    function format(data, opts) {
        return TAformat(data, 'zh_CN', opts)
    }

    function render(nodes, opts) {
        return TArender(nodes, 'zh_CN', opts)
    }

    return {
        format,
        render,
        cancel
    }
}
