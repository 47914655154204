import useBoolen from './useBoolen'

/**
 * @description: 创建一个loading状态
 * @param {*} defaultValue 默认值为false
 */
export default function useLoading(defaultValue = false) {
    const {
        bool: loading,
        setTrue: startLoading,
        setFalse: endLoading
    } = useBoolen(defaultValue)

    return {
        loading,
        startLoading,
        endLoading
    }
}
