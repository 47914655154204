const Layout = () => import('@/layout/index.vue')

export default {
    path: '/biddingPrereport',
    component: Layout,
    redirect: { name: 'prereportList' },
    meta: {
        title: '招标报备',
        icon: 'ci:bulb',
        auth: ['bidding_prereport']
    },
    children: [
        {
            path: 'list',
            name: 'prereportList',
            component: () => import('@/views/bidding/prereport/list.vue'),
            meta: {
                title: '全部报备',
                auth: ['bidding_prereport_list'],
                cache: true
            }
        },

        {
            path: 'add',
            name: 'prereportAdd',
            component: () => import('@/views/bidding/prereport/form.vue'),
            meta: {
                title: '新增报备',
                cache: true,
                auth: ['bidding_prereport_add'],
            }
        },
        {
            path: 'update/:id',
            name: 'prereportUpdate',
            component: () => import('@/views/bidding/prereport/form.vue'),
            meta: {
                title: '修改报备',
                activeMenu: '/project/list',
                sidebar: false
            }
        },
        {
            path: 'view/:id',
            name: 'prereportView',
            component: () => import('@/views/bidding/prereport/view.vue'),
            meta: {
                title: '修改报备',
                activeMenu: '/project/list',
                sidebar: false,
                auth: ['bidding_prereport_view'],
            }
        },
    ]
}
