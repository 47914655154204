/*
import { useGoMessageDetail } from '@/composables/goMessageDetail'
import { seenUserMessage } from '@/api/common/user'
import { useWebNotification } from '@vueuse/core'
const handleNotificationClick = async message => {
    console.log(message)
    if (message.url) {
        useGoMessageDetail(message)
        await seenUserMessage(message.id)
    }
}

export const useNotification = async (message, config = {}) => {
    onClick(evt => {
        console.log(evt)
    })
    const {
        isSupported,
        show,
        onClick
    } = useWebNotification(
        {
            title: message.title,
            body: message.content
        }
    )
    if (isSupported) {
        show()
    } else {
        ElNotification({
            title: message.title,
            message: message.content,
            type: 'success',
            duration: 6000,
            onClick: handleNotificationClick.bind(null, message)
        })
    }

}
*/
import { ElNotification } from 'element-plus'

export const useNotification = (message) => {
    // 检查浏览器是否支持通知
    if (!('Notification' in window)) {
        fallbackToElNotification(message)
        return
    }

    // 检查用户是否允许通知
    if (Notification.permission === 'granted') {
        showBrowserNotification(message)
    } else if (Notification.permission === 'default') {
        // 请求用户授权
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                showBrowserNotification(message)
            } else {
                fallbackToElNotification(message)
            }
        })
    } else {
        // 用户已拒绝授权
        fallbackToElNotification(message)
    }
}

const showBrowserNotification = (message) => {
    const notification = new Notification(message.title, {
        body: message.content,
    })

    // 添加点击通知的事件监听器
    notification.onclick = () => {
        console.log('Browser notification clicked')
        // 跳转到 message.url
        if (message.url) {
            /*  const url = typeof message === 'object' ? message?.url : message*/
            const url = '#/personal/message'
            window.open(url, '_blank')
        }
        // 在这里添加其他操作
    }
}

const fallbackToElNotification = (message) => {
    ElNotification({
        title: message.title,
        message: message.content,
        type: 'success',
        duration: 6000,
        onClick: () => {

            console.log('ElNotification clicked')
            // 跳转到 message.url
            if (message.url) {
                /*  const url = typeof message === 'object' ? message?.url : message*/
                const url = '#/personal/message'
                window.open(url, '_blank')
            }
            // 在这里添加其他操作
        }
    })
}
