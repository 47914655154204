const Layout = () => import('@/layout/index.vue')
export default {
    meta: {
        i18n: 'route.ai.name',
        icon: 'ri:robot-2-line',
        auth: ['ai']
    },
    children: [
        {
            path: '/ai',
            component: Layout,
            meta: {
                i18n: 'route.ai.chatgpt',
                icon: 'ri:robot-2-line',
                auth: ['ai_chatgpt'],
                link: '/chatgpt'
            }
        }
    ]
}
