const Layout = () => import('@/layout/index.vue')

export default {
    path: '/biddingTracking',
    component: Layout,
    redirect: { name: 'biddingTracking' },
    meta: {
        i18n: 'route.bidding.biddingTracking.name',
        icon: 'gridicons:reader-follow-conversation',
        auth: ['bidding_tracking']
    },
    children: [
        {
            path: 'biddingTrackingList',
            name: 'biddingTrackingList',
            component: () => import('@/views/bidding/tracking/list.vue'),
            meta: {
                i18n: 'route.bidding.biddingTracking.list',
                cache: true,
                auth: ['bidding_tracking_list']
            },
            cache: true
        },
        {
            path: 'tracking/view/:id',
            name: 'biddingTrackingView',
            component: () => import('@/views/bidding/tracking/view.vue'),
            meta: {
                i18n: 'route.bidding.biddingTracking.view',
                sidebar: false,
                auth: ['bidding_tracking_view'],
                activeMenu: '/biddingTracking/biddingTrackingList',
                cache: true
            },
            cache: true
        }
    ]
}
