const Layout = () => import('@/layout/index.vue')

export default {
    path: '/opportunity/clue',
    component: Layout,
    redirect: { name: 'opportunityClueList' },
    meta: {
        i18n: 'route.opportunity.clue.name',
        icon: 'icon-park-outline:clue',
        auth: ['opportunity_clue'],
        cache: true
    },
    children: [
        {
            path: 'list',
            name: 'opportunityClueList',
            component: () => import('@/views/opportunity/clue/list.vue'),
            meta: {
                i18n: 'route.opportunity.clue.list',
                cache: true
            }
        },
        {
            path: 'add',
            name: 'opportunityClueAdd',
            component: () => import('@/views/opportunity/clue/form.vue'),
            meta: {
                cache: true,
                sidebar: false,
                activeMenu: '/opportunity/clue/list',
                i18n: 'route.opportunity.clue.add',
                auth: ['opportunity_clue_add']
            }
        },
        {
            path: 'import',
            name: 'opportunityClueImport',
            component: () => import('@/views/opportunity/clue/ImportForm.vue'),
            meta: {
                cache: true,
                sidebar: false,
                activeMenu: '/opportunity/clue/list',
                i18n: 'route.opportunity.clue.add',
                auth: ['opportunity_clue_add']
            }
        },
        {
            path: 'manualImport',
            name: 'opportunityClueManualImport',
            component: () => import('@/views/opportunity/clue/manualImportForm.vue'),
            meta: {
                cache: true,
                sidebar: false,
                activeMenu: '/opportunity/clue/list',
                i18n: 'route.opportunity.clue.add',
                auth: ['opportunity_clue_add']
            }
        },
        {
            path: 'update/:id',
            name: 'opportunityClueUpdate',
            component: () => import('@/views/opportunity/clue/form.vue'),
            meta: {
                cache: true,
                i18n: 'route.opportunity.clue.update',
                sidebar: false,
                activeMenu: '/opportunity/clue/list',
                auth: ['opportunity_clue_update']
            }
        },
        {
            path: 'view/:id',
            name: 'opportunityClueView',
            component: () => import('@/views/opportunity/clue/view.vue'),
            meta: {
                i18n: 'route.opportunity.clue.view',
                sidebar: false,
                activeMenu: '/opportunity/clue/list',
                auth: ['opportunity_clue_view'],
                cache: true
            }
        },
        {
            path: 'customer/list',
            name: 'opportunityClueCustomerList',
            component: () => import('@/views/opportunity/clue/customer/list.vue'),
            meta: {
                i18n: 'route.opportunity.clue.customer.list',
                cache: true
            }
        },
        {
            path: 'customer/add',
            name: 'opportunityClueCustomerAdd',
            component: () => import('@/views/opportunity/clue/customer/form.vue'),
            meta: {
                i18n: 'route.opportunity.clue.customer.add',
                sidebar: false,
                activeMenu: '/opportunity/clue/customer/list'
            }
        },
        {
            path: 'customer/update/:id',
            name: 'opportunityClueCustomerUpdate',
            component: () => import('@/views/opportunity/clue/customer/form.vue'),
            meta: {
                i18n: 'route.opportunity.clue.customer.update',
                sidebar: false,
                activeMenu: '/opportunity/clue/customer/list'
            }
        },
        {
            path: 'customer/view/:id',
            name: 'opportunityClueCustomerView',
            component: () => import('@/views/opportunity/clue/customer/view.vue'),
            meta: {
                i18n: 'route.opportunity.clue.customer.view',
                sidebar: false,
                activeMenu: '/opportunity/clue/customer/list',
                cache: true
            }
        },
        {
            path: 'customer/:customerId/approve/:id',
            name: 'customerApprove',
            component: () => import('@/views/opportunity/clue/customer/approve/view.vue'),
            meta: {
                i18n: 'route.opportunity.clue.customer.approve',
                sidebar: false
            }
        },
        {
            path: 'business-statistics',
            name: 'opportunityClueBusinessStatistics',
            component: () => import('@/views/opportunity/clue/businessStatistics.vue'),
            meta: {
                i18n: 'route.opportunity.clue.businessStatistics',
                cache: true,
                auth: ['opportunity_clue_business']
            }
        },
        {
            path: ':projectId/approve/:id',
            name: 'opportunityClueApprove',
            component: () => import('@/views/opportunity/clue/approve/view.vue'),
            meta: {
                i18n: 'route.common.approve.view',
                sidebar: false
            }
        },
        {
            path: 'revisit/list',
            name: 'opportunityClueRevisitList',
            component: () => import('@/views/opportunity/clue/revisit/list.vue'),
            meta: {
                i18n: 'route.opportunity.clue.revisit.list',
                auth: ['opportunity_clue_revisit'],
                cache: true
            }
        },
        // {
        //     path: 'track/list',
        //     name: 'opportunityClueTrackList',
        //     component: () => import('@/views/opportunity/clue/trackStatistics.vue'),
        //     meta: {
        //         title: '跟踪图表',
        //         cache: true
        //     }
        // },
        {
            path: 'revisit/view/:id',
            name: 'opportunityClueRevisitView',
            component: () => import('@/views/opportunity/clue/revisit/view.vue'),
            meta: {
                i18n: 'route.opportunity.clue.revisit.view',
                auth: ['opportunity_clue_revisit'],
                sidebar: false,
                activeMenu: '/opportunity/clue/revisit/list',
                cache: true
            }
        },
        {
            path: 'high-seas',
            name: 'opportunityClueHighSeasList',
            component: () => import('@/views/opportunity/clue/high-seas/list.vue'),
            meta: {
                i18n: 'route.opportunity.clue.highSeas.list',
                auth: ['opportunity_clue_high_seas'],
                cache: true
            }
        },
        {
            path: 'daily-track',
            name: 'opportunityClueDailyTrack',
            component: () => import('@/views/opportunity/clue/daily-track/dailyTrackTabs.vue'),
            meta: {
                i18n: 'route.opportunity.clue.analyseDailyTrack',
                cache: true,
                auth: ['opportunity_clue_statistics']
            }
        },
        {
            path: 'line-statistics',
            name: 'opportunityClueLineStatistics',
            component: () => import('@/views/opportunity/clue/data-chart/DataChart.vue'),
            meta: {
                i18n: 'route.opportunity.clue.lineStatistics',
                cache: true,
                auth: ['opportunity_clue_statistics']
            }
        },
        {
            path: 'tag',
            name: 'clueTagManage',
            component: () => import('@/views/opportunity/clue/tag/list.vue'),
            meta: {
                title: '平台管理',
                cache: true,
                auth: ['opportunity_clue_pt']
            }
        },
        {
            path: 'tag/view:id',
            name: 'clueTagView',
            component: () => import('@/views/opportunity/clue/tag/view.vue'),
            meta: {
                title: '平台查看',
                cache: true,
                sidebar: false,
                activeMenu: '/opportunity/clue/tag',
            }
        },
        {
            path: 'tag/add',
            name: 'clueTagAdd',
            component: () => import('@/views/opportunity/clue/tag/form.vue'),
            meta: {
                title: '平台新增',
                sidebar: false,
                cache: true,
                activeMenu: '/opportunity/clue/tag',
            }
        },
        {
            path: 'tag/update/:id',
            name: 'clueTagUpdate',
            component: () => import('@/views/opportunity/clue/tag/form.vue'),
            meta: {
                title: '平台修改',
                sidebar: false,
                cache: true,
                activeMenu: '/opportunity/clue/tag',
            }
        }

    ]
}
