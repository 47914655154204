const Layout = () => import('@/layout/index.vue')

export default {
    path: '/opportunity/bidding-project',
    component: Layout,
    redirect: { name: 'opportunityBiddingProjectList' },
    meta: {
        i18n: 'route.opportunity.biddingProject.name',
        icon: 'ant-design:project-outlined',
        auth: ['opportunity_project_old']
    },
    children: [
        {
            path: 'list',
            name: 'opportunityBiddingProjectList',
            component: () => import('@/views/opportunity/bidding-project/list.vue'),
            meta: {
                i18n: 'route.opportunity.biddingProject.list',
                auth: ['opportunity_project_list_old'],
                cache: true
            }
        },
        {
            path: 'add',
            name: 'opportunityBiddingProjectAdd',
            component: () => import('@/views/opportunity/bidding-project/form.vue'),
            meta: {
                i18n: 'route.opportunity.biddingProject.add',
                auth: ['opportunity_project_add_old']
            }
        },
        {
            path: 'view/:id',
            name: 'opportunityBiddingProjectView',
            component: () => import('@/views/opportunity/bidding-project/view.vue'),
            meta: {
                i18n: 'route.opportunity.biddingProject.view',
                sidebar: false,
                activeMenu: '/opportunity/bidding-project/list',
                auth: ['opportunity_project_view_old'],
                cache: true
            }
        },
        {
            path: 'daily-track',
            name: 'opportunityBiddingProjectDailyTrack',
            component: () => import('@/views/opportunity/bidding-project/dailyTrackList.vue'),
            meta: {
                i18n: 'route.opportunity.biddingProject.dailyTrack',
                auth: ['opportunity_project_track_report_old'],
                cache: true
            }
        },
        {
            path: ':projectId/biddingProject/:id',
            name: 'opportunityBiddingProjectApprove',
            component: () => import('@/views/opportunity/bidding-project/approve/view.vue'),
            meta: {
                i18n: 'route.common.approve.view',
                sidebar: false
            }
        },

    ]
}
