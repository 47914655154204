const Layout = () => import('@/layout/index.vue')

export default {
    path: '/system/role',
    component: Layout,
    redirect: '/system/role/list',
    meta: {
        i18n: 'route.system.role.name',
        icon: 'ri:team-line',
        auth: ['system_role']
    },
    children: [
        {
            path: 'list',
            name: 'systemRoleList',
            component: () => import('@/views/system/role/list.vue'),
            meta: {
                i18n: 'route.system.role.list',
                cache: true,
                auth: ['system_role_list']
            }
        },
        {
            path: 'add',
            name: 'systemRoleAdd',
            component: () => import('@/views/system/role/form.vue'),
            meta: {
                i18n: 'route.system.role.add',
                cache: true,
                auth: ['system_role_add']
            }
        },
        {
            path: 'update/:id',
            name: 'systemRoleUpdate',
            component: () => import('@/views/system/role/form.vue'),
            meta: {
                i18n: 'route.system.role.update',
                sidebar: false,
                activeMenu: '/system/role/list',
                cache: false,
                auth: ['system_role_update']
            }
        }
    ]
}
