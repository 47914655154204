import channel from './channel'

const Layout = () => import('@/layout/index.vue')

export default {
    meta: {
        i18n: 'route.bee.menu',
        icon: 'ri:global-line',
        auth: ['bee']
    },
    children: [
        channel,
        {
            path: '/bee/channel/config',
            component: Layout,
            meta: {
                i18n: 'route.bee.config.name',
                icon: 'ri:contract-line'
            },
            children: [
                {
                    path: 'index',
                    name: 'configIndex',
                    component: () => import('@/views/bee/config/index.vue'),
                    meta: {
                        i18n: 'route.bee.config.name',
                        cache: true
                    }
                }
            ]
        }
    ]
}
