const Layout = () => import('@/layout/index.vue')

export default {
    path: '/opportunity/project',
    component: Layout,
    redirect: { name: 'opportunityProjectList' },
    meta: {
        title: '项目库',
        icon: 'ant-design:project-outlined',
        auth: ['opportunity_project']
    },
    children: [
        {
            path: 'list',
            name: 'opportunityProjectList',
            component: () => import('@/views/opportunity/project/list.vue'),
            meta: {
                title: '项目数据',
                auth: ['opportunity_project_list'],
                cache: true
            }
        },
        {
            path: 'add',
            name: 'opportunityProjectAdd',
            component: () => import('@/views/opportunity/project/form.vue'),
            meta: {
                title: '新增项目',
                auth: ['opportunity_project_add']
            }
        },
        {
            path: 'update/:id',
            name: 'opportunityProjectUpdate',
            component: () => import('@/views/opportunity/project/updateForm.vue'),
            meta: {
                title: '修改项目',
                cache: true,
                sidebar: false,
                activeMenu: '/opportunity/project/list',
                auth: ['opportunity_project_update']
            }
        },
        {
            path: 'view/:id',
            name: 'opportunityProjectView',
            component: () => import('@/views/opportunity/project/view.vue'),
            meta: {
                title: '详情',
                sidebar: false,
                activeMenu: '/opportunity/project/list',
                auth: ['opportunity_project_view'],
                cache: true
            }
        },

        {
            path: ':projectId/approve/:workId',
            name: 'opportunityProjectApprove',
            component: () => import('@/views/opportunity/project/approve/view.vue'),
            meta: {
                title: '审批详情',
                sidebar: false
            }
        },

        {
            path: 'company/list',
            name: 'opportunityProjectCompanyList',
            component: () => import('@/views/opportunity/project-company/list.vue'),
            meta: {
                title: '企业列表',
                cache: true,
                auth: ['opportunity_project_company_list']
            }
        },

        {
            path: 'company/view/:id',
            name: 'opportunityProjectCompanyView',
            component: () => import('@/views/opportunity/project-company/view.vue'),
            meta: {
                title: '详情',
                sidebar: false,
                activeMenu: '/opportunity/project/company/list',
                cache: true
            }
        },
        {
            path: 'company/approve/:id/:workId',
            name: 'opportunityProjectApproveView',
            component: () => import('@/views/opportunity/project-company/approve/view.vue'),
            meta: {
                title: '审批详情',
                sidebar: false,
            }
        },
        {
            path: 'company/add',
            name: 'opportunityProjectCompanyAdd',
            component: () => import('@/views/opportunity/project-company/form.vue'),
            meta: {
                title: '新增企业',
                activeMenu: '/opportunity/project/company/list',
                sidebar: false
            }
        },

        {
            path: 'company/update/:id',
            name: 'opportunityProjectCompanyUpdate',
            component: () => import('@/views/opportunity/project-company/form.vue'),
            meta: {
                title: '修改企业',
                cache: true,
                sidebar: false,
                activeMenu: '/opportunity/project/company/list'
            }
        },

        {
            path: 'userSubscribe/list',
            name: 'userSubscribeList',
            component: () => import('@/views/opportunity/user-subscribe/list.vue'),
            meta: {
                title: '订阅列表',
                cache: true,
                auth: ['opportunity_project_user_subscribe_list']
            }
        },

        {
            path: 'userSubscribe/overview',
            name: 'userSubscribeOverview',
            component: () => import('@/views/opportunity/user-subscribe/overview.vue'),
            meta: {
                title: '订阅总览',
                cache: true,
                sidebar: false,
                auth: ['opportunity_project_user_subscribe_list'],
                activeMenu: '/opportunity/project/userSubscribe/list'
            }
        },

        {
            path: 'userSubscribe/add',
            name: 'opportunityProjectUserSubscribeAdd',
            component: () => import('@/views/opportunity/user-subscribe/form.vue'),
            meta: {
                title: '新增订阅',
                activeMenu: '/opportunity/project/userSubscribeList/list',
                sidebar: false
            }
        },

        {
            path: 'userSubscribe/update/:id',
            name: 'opportunityProjectUserSubscribeUpdate',
            component: () => import('@/views/opportunity/user-subscribe/form.vue'),
            meta: {
                title: '修改订阅',
                cache: true,
                sidebar: false,
                activeMenu: '/opportunity/project/userSubscribe/list'
            }
        },

        {
            path: 'userRecord/list',
            name: 'userRecordList',
            component: () => import('@/views/opportunity/user-record/list.vue'),
            meta: {
                title: '跟进记录',
                cache: true,
                auth: ['opportunity_project_user_record_list']
            }
        },
        {
            path: 'industry/list',
            name: 'industryList',
            component: () => import('@/views/opportunity/industry/list.vue'),
            meta: {
                title: '行业管理',
                cache: true,
                auth: ['opportunity_project_industry_list']
            }
        },
        {
            path: 'industry/add',
            name: 'opportunityProjectIndustryAdd',
            component: () => import('@/views/opportunity/industry/form.vue'),
            meta: {
                title: '新增行业',
                activeMenu: '/opportunity/project/industry/list',
                sidebar: false,
                auth: ['opportunity_project_industry_add']

            }
        },

        {
            path: 'industry/update/:id',
            name: 'opportunityProjectIndustryUpdate',
            component: () => import('@/views/opportunity/industry/form.vue'),
            meta: {
                title: '修改行业',
                sidebar: false,
                activeMenu: '/opportunity/project/industry/list',
                auth: ['opportunity_project_industry_update']
            }
        },
        {
            path: 'industry/view/:id',
            name: 'opportunityProjectIndustryView',
            component: () => import('@/views/opportunity/industry/view.vue'),
            meta: {
                title: '详情',
                sidebar: false,
                activeMenu: '/opportunity/project/industry/list',
                cache: true

            }
        },
        {
            path: 'report',
            name: 'opportunityProjectReport',
            component: () => import('@/views/opportunity/project/report/view.vue'),
            meta: {
                title: '项目报表',
                auth: ['opportunity_project_report'],
                cache: true
            }
        },
        {
            path: 'highSeasList',
            name: 'opportunityProjectHighSeasList',
            component: () => import('@/views/opportunity/project/highSeas/list.vue'),
            meta: {
                title: '项目公海',
                auth: ['opportunity_project_high_seas_list'],
                cache: true
            }
        },
        {
            path: 'highSeas/view/:id',
            name: 'opportunityProjectHighSeasView',
            component: () => import('@/views/opportunity/project/highSeas/view.vue'),
            meta: {
                title: '详情',
                sidebar: false,
                activeMenu: '/opportunity/project/highSeasList',
                auth: ['opportunity_project_view'],
                cache: true
            }
        }
    ]
}
