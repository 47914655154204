import { getRequest, postRequest } from '@/api'
/**
 * 发送验证码
 * @param mobile 手机号
 * @returns {*|Promise<AxiosResponse<any>>}
 */
export function sendSmsCaptcha(mobile) {
    return getRequest({
        url: '/auth/sendSmsCaptcha',
        params: { mobile }
    })
}

/**
 * 登录
 * @param data
 * @returns {*|Promise<AxiosResponse<any>>}
 */
export function login(data) {
    return postRequest({
        url: '/auth/login',
        data
    })
}

/**
 * 注销
 * @param params
 * @returns {*|Promise<AxiosResponse<any>>}
 */
export function logout() {
    return postRequest({
        url: '/auth/logout'
    })
}
