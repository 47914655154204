const Layout = () => import('@/layout/index.vue')

export default {
    path: '/opportunity/bidding-forecast',
    component: Layout,
    redirect: { name: 'opportunityBiddingForecastList' },
    meta: {
        i18n: 'route.opportunity.biddingForecast.name',
        icon: 'ic:outline-work-history',
        auth: ['opportunity_bidding-forecast']
    },
    children: [
        {
            path: 'list',
            name: 'opportunityBiddingForecastList',
            component: () => import('@/views/opportunity/bidding-forecast/list.vue'),
            meta: {
                i18n: 'route.opportunity.biddingForecast.list',
                cache: true
            }
        },
        {
            path: 'daily-track',
            name: 'opportunityBiddingForecastDailyTrack',
            component: () => import('@/views/opportunity/bidding-forecast/dailyTrackList.vue'),
            meta: {
                i18n: 'route.opportunity.biddingForecast.dailyTrack',
                cache: true
            }
        },
        {
            path: 'view/:id',
            name: 'opportunityBiddingForecastView',
            component: () => import('@/views/opportunity/bidding-forecast/view.vue'),
            meta: {
                i18n: 'route.opportunity.biddingForecast.view',
                sidebar: false,
                auth: ['opportunity_bidding-forecast_view'],
                activeMenu: '/opportunity/bidding-forecast/list'
            }
        },
        {
            path: ':forecastId/approve/:id',
            name: 'opportunityBiddingForecastApprove',
            component: () => import('@/views/opportunity/bidding-forecast/approve/approveView.vue'),
            meta: {
                i18n: 'route.common.approve.view',
                sidebar: false
            },
            cache: true
        }
    ]
}
