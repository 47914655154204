import bidding from '@/router/modules/bidding/bidding'
import approve from '@/router/modules/bidding/approve'
import biddingTracking from '@/router/modules/bidding/biddingTracking'
// import biddingWork from '@/router/modules/bidding/biddingWork'
import tenderInfo from '@/router/modules/bidding/tenderInfo'
import biddingAnalyse from '@/router/modules/bidding/biddingAnalyse'
import biddingPrereport from '@/router/modules/bidding/biddingPrereport'

export default {
    meta: {
        i18n: 'route.bidding.name',
        icon: 'ic:outline-work',
        auth: ['bidding']
    },
    children: [
        // biddingWork,
        bidding,
        biddingTracking,
        biddingPrereport,
        tenderInfo,
        approve,
        biddingAnalyse
    ]
}
