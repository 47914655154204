const Layout = () => import('@/layout/index.vue')

export default {
    path: '/oversea/project',
    component: Layout,
    redirect: {name: 'overseaProjectList'},
    meta: {
        title: '海外项目库',
        icon: 'ant-design:project-outlined',
        auth: ['opportunity_oversea_project']
    },
    children: [
        {
            path: 'list',
            name: 'overseaProjectList',
            component: () => import('@/views/oversea/project/list.vue'),
            meta: {
                title: '项目数据',
                auth: ['opportunity_oversea_project_list'],
                cache: true
            }
        },
        {
            path: 'recordList',
            name: 'overseaProjectRecordList',
            component: () => import('@/views/oversea/project/record-list/list.vue'),
            meta: {
                title: '跟进记录',
                auth: ['opportunity_oversea_project_user_record_list'],
                cache: true
            }
        },
        {
            path: 'add',
            name: 'overseaProjectAdd',
            component: () => import('@/views/oversea/project/form.vue'),
            meta: {
                title: '新增项目',
                auth: ['opportunity_oversea_project_add']
            }
        },
        {
            path: 'update/:id',
            name: 'overseaProjectUpdate',
            component: () => import('@/views/oversea/project/updateForm.vue'),
            meta: {
                title: '修改项目',
                cache: true,
                sidebar: false,
                activeMenu: '/oversea/project/list',
                auth: ['opportunity_oversea_project_update']
            }
        },
        {
            path: 'view/:id',
            name: 'overseaProjectView',
            component: () => import('@/views/oversea/project/view.vue'),
            meta: {
                title: '详情',
                sidebar: false,
                activeMenu: '/oversea/project/list',
                auth: ['opportunity_oversea_project_view'],
                cache: true
            }
        },

        {
            path: ':projectId/approve/:workId',
            name: 'overseaProjectApprove',
            component: () => import('@/views/oversea/project/approve/view.vue'),
            meta: {
                title: '审批详情',
                sidebar: false
            }
        },
        {
            path: 'location/list',
            name: 'locationList',
            component: () => import('@/views/oversea/location/list.vue'),
            meta: {
                title: '地区管理',
                cache: true,
                auth: ['opportunity_oversea_location_list'],
            }
        },
        {
            path: 'location/add',
            name: 'locationAdd',
            component: () => import('@/views/oversea/location/form.vue'),
            meta: {
                title: '新增地区',
                activeMenu: '/oversea/location/list',
                sidebar: false,
                auth: ['opportunity_oversea_location_add'],

            }
        },

        {
            path: 'location/update/:id',
            name: 'locationUpdate',
            component: () => import('@/views/oversea/location/form.vue'),
            meta: {
                title: '修改地区',
                sidebar: false,
                activeMenu: '/oversea/location/list',
                auth: ['opportunity_oversea_location_update'],
            }
        },
        {
            path: 'location/view/:id',
            name: 'locationView',
            component: () => import('@/views/oversea/location/view.vue'),
            meta: {
                title: '详情',
                sidebar: false,
                activeMenu: '/oversea/location/list',
                auth: ['opportunity_oversea_location_view'],
                cache: true

            }
        },
    ]
}
