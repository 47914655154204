import { EventSourcePolyfill } from 'event-source-polyfill'
import { getMineMessage } from '@/api/message/message'

import useUserStore from '@/store/modules/user'
import api from '@/api'
import { useNotification } from '@/composables/useNotification'
import { useLocalStorage } from '@vueuse/core'

const messageCount = useLocalStorage('messageCount')

const useMessageStore = defineStore(
    'message',
    {
        state: () => ({
            messData: {},
            count: 0
        }),
        actions: {
            getStream() {
                initMessagesStream()
            },
            async getNotSeenNum() {
                this.count = 0
                this.count = await initMessageNumb()
            }
        }

    }
)
const eventSourceRef = ref()
const eventSource = ref(null)
const initMessagesStream = () => {
    const userStore = useUserStore()
    const messageStreamUrl = `${api.defaults.baseURL}/mc/message/stream`
    eventSource.value = new EventSourcePolyfill(messageStreamUrl, {
        headers: {
            Authorization: userStore.token
        }
    })
    eventSource.value.onmessage = async event => {
        const res = JSON.parse(event.data)
        console.log('Received message:', res)
        if (res.id) {
            useNotification(res)
        }

        eventSourceRef.value = event
    }
    eventSource.value.onerror = e => {
        console.log('SSE error:', e)
        // closeMessagesStream()
        // reconnect()
    }
}

async function initMessageNumb() {
    const params = {
        seen: false,
        page: 1,
        pageSize: 1
    }
    const res = await getMineMessage(params)
    return res.totalItems
}

const closeMessagesStream = () => {
    if (eventSource.value) {
        eventSource.value.close()
    }
}

onMounted(() => {
    console.log('initMessage')
    initMessagesStream()
    initMessageNumb()
})

onUnmounted(() => {
    closeMessagesStream()
})

export default useMessageStore
