import axios from 'axios'
import { useSessionStorage } from '@vueuse/core'

const useVersion = defineStore(
    'version',
    {
        state: () => ({
            version: useSessionStorage('version', null),
            needUpgrade: false
        }),
        actions: {
            async check() {
                const resp = await axios.get('version.json').catch(err => {
                    console.error('checkVersion error: ', err)
                    this.needUpgrade = false
                })
                // 当本地不存在 version 时，将 version 设置为当前版本
                if (this.version === null) {
                    this.version = resp.data.version
                    this.needUpgrade = false
                }
                // 比对本地版本与远程版本
                if (this.version !== resp.data.version) {
                    this.needUpgrade = true
                } else {
                    this.needUpgrade = false
                }
            },
            async clear() {
                this.version = null
                this.needUpgrade = false
            },
            async upgrade() {
                window.location.reload()
            }
        }
    }
)

export default useVersion
