const Layout = () => import('@/layout/index.vue')

export default {
    path: '/approve',
    component: Layout,
    redirect: { name: 'biddingApproveView' },
    meta: {
        i18n: 'route.common.approve.name',
        icon: 'ant-design:project-outlined',
        sidebar: false
    },
    children: [
        {
            path: ':id/bidding/:trackId',
            name: 'biddingTrackingApproveView',
            component: () => import('@/views/bidding/tracking/approveView.vue'),
            meta: {
                i18n: 'route.common.approve.view',
                sidebar: false
            },
            cache: true
        }
    ]
}
