const Layout = () => import('@/layout/index.vue')

const certifiChildren = [
    {
        path: 'platform',
        name: 'certificationPlatformList',
        component: () => import('@/views/certification/list.vue'),
        meta: {
            i18n: 'route.certification.platform',
            cache: ['certificationView', 'certificationAdd', 'certificationUpdate'],
            auth: ['certification_data_platform'],
            customIds: [55]
        }
    },
    {
        path: 'zdbyq',
        name: 'certificationZDBYQList',
        component: () => import('@/views/certification/list.vue'),
        meta: {
            i18n: 'route.certification.zdbyq',
            cache: ['certificationView', 'certificationAdd', 'certificationUpdate'],
            auth: ['certification_data_zdbyq'],
            customIds: [1]
        }
    },
    {
        path: 'xczl',
        name: 'certificationXCZLList',
        component: () => import('@/views/certification/list.vue'),
        meta: {
            i18n: 'route.certification.xczl',
            cache: ['certificationView', 'certificationAdd', 'certificationUpdate'],
            auth: ['certification_data_xczl'],
            customIds: [59]
        }
    },
    {
        path: 'yjsj',
        name: 'certificationJYSJList',
        component: () => import('@/views/certification/list.vue'),
        meta: {
            i18n: 'route.certification.yjsj',
            cache: ['certificationView', 'certificationAdd', 'certificationUpdate'],
            auth: ['certification_data_yjsj'],
            customIds: [3, 73]
        }
    },
    {
        path: 'jdyj',
        name: 'certificationJDYJList',
        component: () => import('@/views/certification/list.vue'),
        meta: {
            i18n: 'route.certification.jdyj',
            cache: ['certificationView', 'certificationAdd', 'certificationUpdate'],
            auth: ['certification_data_jdyj'],
            customIds: [94]
        }
    },
    {
        path: 'gfkj',
        name: 'certificationGFKJList',
        component: () => import('@/views/certification/list.vue'),
        meta: {
            i18n: 'route.certification.gfkj',
            cache: ['certificationView', 'certificationAdd', 'certificationUpdate'],
            auth: ['certification_data_gfkj'],
            customIds: [96]
        }
    },
    {
        path: 'study',
        name: 'certificationStudyList',
        component: () => import('@/views/certification/list.vue'),
        meta: {
            i18n: 'route.certification.study',
            cache: ['certificationView', 'certificationAdd', 'certificationUpdate'],
            auth: ['certification_data_study'],
            customIds: [89]
        }
    },
    {
        path: 'add',
        name: 'certificationAdd',
        component: () => import('@/views/certification/form.vue'),
        meta: {
            i18n: 'route.certification.add',
            sidebar: false,
            activeMenu: '/certification/list',
            auth: ['certification_add']
        }
    },
    {
        path: 'view/:certificationId',
        name: 'certificationView',
        component: () => import('@/views/certification/view.vue'),
        meta: {
            i18n: 'route.certification.edit',
            sidebar: false,
            activeMenu: '/certification/list',
            auth: ['certification_view', 'certification_update'],
            cache: true
        }
    },
    {
        path: 'update/:id',
        name: 'certificationUpdate',
        component: () => import('@/views/certification/form.vue'),
        meta: {
            i18n: 'route.certification.edit',
            sidebar: false,
            activeMenu: '/certification/list',
            auth: ['certification_view', 'certification_update']
        }
    }
]

export default {
    path: '/certification',
    redirect: { name: 'certificationList' },
    component: Layout,
    meta: {
        i18n: 'route.certification.data',
        icon: 'ri:database-line',
        auth: ['certification_data']
    },
    children: certifiChildren
}
