const Layout = () => import('@/layout/index.vue')

export default {
    path: '/opportunity/customer-info',
    component: Layout,
    redirect: { name: 'opportunityCustomerInfoList' },
    meta: {
        i18n: 'route.opportunity.customerInfo.name',
        icon: 'raphael:customer',
        auth: ['opportunity_customer']
    },
    children: [
        {
            path: 'list',
            name: 'opportunityCustomerInfoList',
            component: () => import('@/views/opportunity/customer-info/list.vue'),
            meta: {
                i18n: 'route.opportunity.customerInfo.list',
                cache: true
            }
        },
        {
            path: 'add',
            name: 'opportunityCustomerInfoAdd',
            component: () => import('@/views/opportunity/customer-info/form.vue'),
            meta: {
                i18n: 'route.opportunity.customerInfo.add'
            }
        },
        {
            path: 'update/:id',
            name: 'opportunityCustomerInfoUpdate',
            component: () => import('@/views/opportunity/customer-info/form.vue'),
            meta: {
                i18n: 'route.opportunity.customerInfo.update',
                sidebar: false,
                activeMenu: '/opportunity/customer-info/list'
            }
        },
        {
            path: 'view/:id',
            name: 'opportunityCustomerInfoView',
            component: () => import('@/views/opportunity/customer-info/view.vue'),
            meta: {
                i18n: 'route.opportunity.customerInfo.view',
                sidebar: false,
                activeMenu: '/opportunity/customer-info/list',
                cache: true
            }
        },
        {
            path: 'trackList',
            name: 'opportunityCustomerInfoTrackList',
            component: () => import('@/views/opportunity/customer-info/trackList.vue'),
            meta: {
                i18n: 'route.opportunity.customerInfo.dailyTrack',
                cache: true
            }
        },
        {
            path: ':customerInfoId/approve/:id/',
            name: 'opportunityCustomerInfoApprove',
            component: () => import('@/views/opportunity/customer-info/approve/view.vue'),
            meta: {
                i18n: 'route.common.approve.view',
                sidebar: false
            }
        }
    ]
}
