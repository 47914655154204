const Layout = () => import('@/layout/index.vue')

const completeWork = {
    path: 'complete',
    component: Layout,
    redirect: { name: 'completeWork' },
    meta: {
        i18n: 'route.work.complete.name'
    },
    children: [
        {
            path: '',
            name: 'completeWork',
            component: () => import('@/views/work/complete/list.vue'),
            meta: {
                i18n: 'route.work.complete.name',
                sidebar: false,
                activeMenu: '/work/complete'
            }
        }
    ]
}

const tracking = {
    path: 'tracking',
    component: Layout,
    redirect: { name: 'workTrackingList' },
    meta: {
        i18n: 'route.work.tracking.name',
        badgeKey: 'trackingCount'
    },
    children: [
        {
            path: '',
            name: 'workTrackingList',
            component: () => import('@/views/work/tracking/list.vue'),
            meta: {
                i18n: 'route.work.tracking.name',
                sidebar: false,
                activeMenu: '/work/tracking'
            }
        },
        {
            path: 'view',
            name: 'workTrackingView',
            component: () => import('@/views/work/view.vue'),
            meta: {
                i18n: 'route.work.tracking.view',
                sidebar: false,
                activeMenu: '/work/tracking',
                cache: true
            }
        }
    ]
}

const todo = {
    path: 'todo',
    name: 'workTodoList',
    component: () => import('@/views/work/todo/list.vue'),
    meta: {
        i18n: 'route.work.todo.name',
        badgeKey: 'todoCount',
        cache: true
    },
    children: [
        {
            path: 'view',
            name: 'workTodoView',
            component: () => import('@/views/work/view.vue'),
            meta: {
                i18n: 'route.work.todo.view',
                sidebar: false,
                activeMenu: '/work/todo',
                cache: true
            }
        }
    ]
}

const work = {
    path: '/work',
    redirect: { name: 'workTodoList' },
    component: Layout,
    meta: {
        i18n: 'route.work.name_sub',
        icon: 'ic:outline-work-outline',
        cache: true,
        defaultOpened: true
    },
    children: [
        todo,
        tracking,
        completeWork
    ]
}
export default {
    meta: {
        i18n: 'route.work.name',
        icon: 'ic:outline-work-outline'
    },
    children: [work]
}

