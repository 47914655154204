const Layout = () => import('@/layout/index.vue')

export default {
    path: '/opportunity/design-institute',
    component: Layout,
    meta: {
        i18n: 'route.opportunity.designInstitute.name',
        icon: 'fluent-mdl2:design',
        auth: ['opportunity_design-institute']
    },
    children: [
        {
            path: 'list',
            name: 'opportunityDesignInstituteList',
            component: () => import('@/views/opportunity/design-institute/list.vue'),
            meta: {
                i18n: 'route.opportunity.designInstitute.list',
                cache: true,
                auth: ['opportunity_design-institute_list']
            }
        },
        {
            path: 'add',
            name: 'opportunityDesignInstituteAdd',
            component: () => import('@/views/opportunity/design-institute/form.vue'),
            meta: {
                i18n: 'route.opportunity.designInstitute.add',
                auth: ['opportunity_design-institute_add']
            }
        },
        {
            path: 'update/:id',
            name: 'opportunityDesignInstituteUpdate',
            component: () => import('@/views/opportunity/design-institute/form.vue'),
            meta: {
                i18n: 'route.opportunity.designInstitute.update',
                sidebar: false,
                activeMenu: '/opportunity/design-institute/list',
                auth: ['opportunity_design-institute_update']
            }
        },
        {
            path: 'view/:id',
            name: 'opportunityDesignInstituteView',
            component: () => import('@/views/opportunity/design-institute/view.vue'),
            meta: {
                i18n: 'route.opportunity.designInstitute.view',
                sidebar: false,
                activeMenu: '/opportunity/design-institute/list',
                cache: true,
                auth: ['opportunity_design-institute_view']
            }
        }
    ]
}
