import clockIn from './clockIn'

export default {
    meta: {
        i18n: 'route.office.name',
        icon: 'ri:file-word-2-line',
        auth: ['oa_attendance']
    },
    children: [
        clockIn
    ]
}
