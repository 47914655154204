const Layout = () => import('@/layout/index.vue')

export default {
    path: '/certification/matter',
    redirect: { name: 'certificationMatter' },
    component: Layout,
    meta: {
        i18n: 'route.certification.matter',
        icon: 'ri:presentation-line',
        auth: ['certification_certification']
    },
    children: [
        {
            path: '',
            name: 'certificationMatter',
            component: () => import('@/views/certification/matter/index.vue'),
            meta: {
                i18n: 'route.certification.matter_create'
            }
        }
    ]
}
