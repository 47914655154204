const Layout = () => import('@/layout/index.vue')

export default {
    path: '/tenderInfo',
    component: Layout,
    redirect: {name: 'tenderInfo'},
    meta: {
        i18n: 'route.tenderInfo.listName',
        icon: 'icon-park-solid:doc-success',
        auth: ['bidding_tender']
    },
    children: [
        {
            path: 'list',
            name: 'tenderList',
            component: () => import('@/views/tender/list.vue'),
            meta: {
                i18n: 'route.tenderInfo.list',
                cache: true,
                auth: ['bidding_tender_list']
            },
            cache: true
        },
        {
            path: 'todayList',
            name: 'todayList',
            component: () => import('@/views/tender/todayList.vue'),
            meta: {
                i18n: 'route.tenderInfo.todayList',
                cache: true,
                auth: ['bidding_tender_list'],
                badgeKey: 'openTenderCount'
            },
            cache: true
        },
        {
            path: 'alreadyList',
            name: 'alreadyList',
            component: () => import('@/views/tender/alreadyList.vue'),
            meta: {
                i18n: 'route.tenderInfo.alreadyList',
                cache: true,
                auth: ['bidding_tender_list']
            },
            cache: true
        },
        {
            path: 'view/:id',
            name: 'tenderView',
            component: () => import('@/views/tender/view.vue'),
            meta: {
                i18n: 'route.tenderInfo.view',
                sidebar: false,
                activeMenu: '/tenderInfo/list',
                auth: ['bidding_tender_view'],
                cache: true
            }
        },
        {
            path: ':tenderId/approve/:id',
            name: 'tenderInfoApprove',
            component: () => import('@/views/tender/approve/view.vue'),
            meta: {
                i18n: 'route.common.approve.view',
                sidebar: false
            }
        }
    ]
}
