import user from './user'
import role from './role'
import permission from './permission'
// import message from './message'
import file from './file'
import team from './team'

export default {
    meta: {
        i18n: 'route.system.name',
        icon: 'eos-icons:system-ok-outlined',
        auth: ['system']
    },
    children: [
        user,
        role,
        permission,
        // message,
        team,
        file
    ]
}
