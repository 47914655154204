const Layout = () => import('@/layout/index.vue')

export default {
    path: '/system/file',
    component: Layout,
    redirect: '/system/file/list',
    meta: {
        i18n: 'route.system.file.name',
        icon: 'ri:folder-settings-line'
    },
    children: [
        {
            path: 'list',
            name: 'systemFileList',
            component: () => import('@/views/system/file/list.vue'),
            meta: {
                i18n: 'route.system.file.list'
            }
        },
        {
            path: 'add',
            name: 'systemFileAdd',
            component: () => import('@/views/system/file/form.vue'),
            meta: {
                i18n: 'route.system.file.add',
                cache: true
            }
        },
        {
            path: 'update/:id',
            name: 'systemFileUpdate',
            component: () => import('@/views/system/file/form.vue'),
            meta: {
                i18n: 'route.system.file.update',
                sidebar: false,
                activeMenu: '/system/file/list',
                cache: true
            }
        }

    ]
}
