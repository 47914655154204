import axios from 'axios'
import router from '@/router/index'
import useUserStore from '@/store/modules/user'
import { ElMessage } from 'element-plus'
import useLoading from '@/composables/useLoading'

const { loading, startLoading, endLoading } = useLoading(false)

/**
 * 跳转到登录页
 * @returns {Promise<void>}
 */
async function toLogin() {
    const userStore = useUserStore()
    await userStore.logout()
    await router.push({
        name: 'login',
        query: {
            redirect: router.currentRoute.value.path !== '/common' ? router.currentRoute.value.fullPath : undefined
        }
    })
}

/**
 * 创建axios实例
 * @type {AxiosInstance}
 */
const api = axios.create({
    baseURL: import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true' ? '/proxy/' : import.meta.env.VITE_APP_API_BASEURL,
    timeout: 10000,
    responseType: 'json'
})
/**
 * 请求预处理
 */
api.interceptors.request.use(
    request => {
        request?.showLoading !== false && startLoading()

        // 仅在开发环境下显示参数
        if (import.meta.env.VITE_ALLOW_SHOW_REQUEST_PARAMS === 'true') {
            request?.data && ElMessage.info(JSON.stringify(request.data))
            request?.params && ElMessage.info(JSON.stringify(request.params))
        }

        const userStore = useUserStore()
        if (userStore.isLogin) {
            request.headers['Authorization'] = userStore.token
        }
        return request
    },
    error => {
        endLoading()
        return Promise.reject(error)
    }
)

/**
 * 响应统一处理
 */
api.interceptors.response.use(
    response => {
        endLoading()
        return Promise.resolve(response.data)
    },
    error => {
        if (error.config.donotShowError === true) {
            endLoading()
            return Promise.reject(data)
        }
        endLoading()
        if (error.response) {
            const { response: { status, data } } = error
            switch (status) {
                case 401:
                    if (data.code !== 1006) {
                        // 1006 不弹出错误提示
                        ElMessage.error(data.message)
                    }
                    // code 是 1000 跳转登录页
                    if (data.code === 1000) {
                        toLogin()
                    }
                    break
                case 503:
                    // 503 网关服务不可用异常
                    ElMessage.error(error.response.data.error)
                    break
                default:
                    // 500 全局弹出message
                    // 0 网络异常，data为null
                    // 处理blob类型报错
                    if (error.response.data instanceof Blob) {
                        handleBlobError(error.response.data)
                    } else {
                        ElMessage.error(data ? data.message : error.message)
                    }
                    break
            }
            // 抛出异常，在业务代码中处理
            return Promise.reject(data)
        } else {
            ElMessage.error(error.message)
        }
    }
)

/**
 * 处理blob类型报错
 * @param blob Blob类型对象
 */
async function handleBlobError(blob) {
    // 读取Blob对象，转为文本
    const jsonStr = await blob.text()
    const responseObj = JSON.parse(jsonStr)
    ElMessage.error(responseObj.message)
}

export function getRequest(config) {
    return api.request({ ...config, method: 'GET' })
}

export function postRequest(config) {
    return api.request({ ...config, method: 'POST' })
}

export function putRequest(config) {
    return api.request({ ...config, method: 'PUT' })
}

export function patchRequest(config) {
    return api.request({ ...config, method: 'PATCH' })
}

export function deleteRequest(config) {
    return api.request({ ...config, method: 'DELETE' })
}

export function uploadFile(config) {
    const data = new FormData()
    const params = config.data
    Object.keys(params).forEach(key => data.append(key, params[key]))
    return api.request({
        ...config,
        data,
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8'
        }
    })
}

export {
    loading
}

export default api
