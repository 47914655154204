const Layout = () => import('@/layout/index.vue')

export default {
    path: '/certification/manage',
    redirect: { name: 'certificationList' },
    component: Layout,
    meta: {
        i18n: 'route.certification.manage',
        icon: 'ri:list-settings-line',
        auth: ['certification_certification']
    },
    children: [
        {
            path: 'rule',
            name: 'certificationRule',
            component: () => import('@/views/certification/rule/list.vue'),
            meta: {
                i18n: 'route.certification.rule.config',
                auth: ['certification_rule_list']
            }
        },
        {
            path: 'category',
            name: 'certificationCategory',
            component: () => import('@/views/certification/category/list.vue'),
            meta: {
                i18n: 'route.certification.category.config',
                auth: ['certification_category_list']
            }
        },
        {
            path: 'category/add',
            name: 'certificationCategoryAdd',
            component: () => import('@/views/certification/category/form.vue'),
            meta: {
                i18n: 'route.certification.category.config',
                sidebar: false,
                activeMenu: '/certification/manage/category',
                auth: ['certification_category_add']
            }
        },
        {
            path: 'category/:id',
            name: 'certificationCategoryEdit',
            component: () => import('@/views/certification/category/form.vue'),
            meta: {
                i18n: 'route.certification.category.config',
                sidebar: false,
                activeMenu: '/certification/manage/category',
                auth: ['certification_category_view', 'certification_category_update']
            }
        },
        {
            path: 'category/view/:categoryId',
            name: 'certificationCategoryView',
            component: () => import('@/views/certification/category/view.vue'),
            meta: {
                i18n: 'route.certification.category.config',
                sidebar: false,
                activeMenu: '/certification/manage/category',
                auth: ['certification_category_view']
            }
        }
    ]
}
